import React, { useState, FormEvent, SetStateAction} from 'react';
import { useCookies } from 'react-cookie';
import PropTypes from 'prop-types';
// import fetch from 'fetch';

import './loginComponent.css';

//const server='http://localhost:3001';
const server='http://3.89.5.86:3001'
//const server='http://24.34.13.151:3001';

//type LoginProps = {
//  setCookie: Function
//}


async function loginUser(email: string | undefined, password: string | undefined, setCookie: Function): Promise<boolean> {
  const response = await fetch(`${server}/login`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'same-origin',
    body: JSON.stringify({email, password}),
  });

  // Try and make another
  const resp2 = await fetch(`${server}/users`, {
    method: 'GET',
    credentials: 'same-origin',    
  });
  const raw = await resp2.json();
  console.log('my email', raw.data[0].email);

  // await axios.post('http://localhost:3001/login', {email, password}, {withCredentials: true});
  console.log('fetch response', response.status);
  console.log('headers', Object.keys(response.headers))
  if (response.status != 200) {
    // Failure case
    return false;
  }

  // Save cookies
  //setCookie(Cookies
  // console.log('current cookies', cookies.getAll());

  const auth:Headers = response.headers; // .Authorization;
  console.log('auth', auth, response.headers.get('Set-Cookie'));

  //console.log('cookies', JSON.stringify(response.headers['Set-Cookie'], null, 2));
  return true;
}

interface LoginComponentProps {
  enable(): void;
};

export const LoginComponent = (props: LoginComponentProps) => {
  //(login: boolean) => void) => {
  const [email, setEmail] = useState<string | undefined>();
  const [password, setPassword] = useState<string | undefined>();
  const [cookies, setCookie] = useCookies(['Authorization']);


  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const ok = await loginUser(email, password, setCookie);
    if (ok) {
      //console.log('passing', ok, 'to', props.setLogin);
      //props.setLogin(ok);
      props.enable();
    }
    //console.log('event', e);
    //console.log('current cookies', cookies);
  }

  return (
    <div className="login-wrapper">
      <h2>Login</h2>
      <form onSubmit={e => handleSubmit(e)}>
      <label>
        <p>Email</p>
        <input type="text" onChange={e => setEmail(e.target.value)} />
      </label>
      <label>
        <p>Password</p>
        <input type="password" onChange={e => setPassword(e.target.value)} />
      </label>
      <div>
        <button type="submit">Submit</button>
      </div>
    </form>

    </div>
  )
}


//LoginComponent.propTypes = {
  //setCookie: PropTypes.func.isRequired
//}
