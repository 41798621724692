import React, { useState, FormEvent} from 'react';
import { FaArrowAltCircleRight } from 'react-icons/fa';
import { useCookies } from 'react-cookie';
import PropTypes from 'prop-types';

import './fixUrlComponent.css';

interface FacilityProps {
  record: any,
}

interface FacilityEditProps {
  facilityid: string,
  entity: string,
  enabled: boolean,
}

//const server='http://localhost:3001';
//const server='http://24.34.13.151:3001';
const server='http://3.89.5.86:3001'


export const FacilityEditComponent = ({enabled, facilityid, entity}: FacilityEditProps) => {
  const [newUrl, setNewUrl] = useState<string | undefined>();

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!newUrl?.includes('http://') && !newUrl?.includes('https://')) {
      console.log('invalid url', newUrl);
      return;
    }
    console.log('Clicked', facilityid, newUrl);

    const body = {
      facilityid,
      url: newUrl,
      fetchmethod: 'manual'
    };

    const response = await fetch(`${server}/facility/updateurl`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: 'same-origin',
      body: JSON.stringify(body),
    });

    // If ok, remove this element
    console.log('response', response);
  }

  if (!enabled) {
    return null;
  }

  return (
    <form onSubmit={handleSubmit}>
      <label>New Url
        <input type="text" style={{width: 500}} onChange={e => setNewUrl(e.target.value)} />
      </label>
      <input type="submit" name="Submit" />
    </form>
  )
}

export const FacilityComponent = ({record}: FacilityProps) => {
  const [selected, setSelected] = useState<boolean>(false);

  const handleClick = async (e: FormEvent<HTMLDivElement>) => {
    // Only respond to the click from the span, not an input element
    const span = e.target instanceof HTMLSpanElement;
    // console.log('click', e.target);
    if (span) {
      setSelected(!selected);
    }
  }

  const search = async (e: React.MouseEvent<SVGElement, MouseEvent>) => {
    // Address is inside details
    let address = '';
    if (record.details) {
      try {
        address = JSON.parse(record.details).ADDRESS;
      }
      catch (ex) {}
    }
    console.log(record);
    const query = record.name + ' ' + address + record.city + ' ' + record.state;
    window.open('https://google.com/search?q='+query, '_blank'); 
  }

  return (
    <div onClick={handleClick} className={record.index % 2 ? "list-wrapper-even" : "list-wrapper-odd"}>
      <span>{record.name}<FaArrowAltCircleRight onClick={search}/></span>
      <span>{record.city}&nbsp;{record.state}</span>
      <span>{record.baseurl}</span>
      <FacilityEditComponent facilityid={record.facilityid} entity={record.name + ' ' + record.city + ' ' + record.state} enabled={selected}/>
    </div>
  );
}

async function getFacilities(): Promise<Array<any>> {
  // Get list of urls
  const response = await fetch(`${server}/facility/fix`, {
    method: 'GET',
    credentials: 'same-origin',
  });

  if (response.status != 200) {
    // Failure case
    return [];
  }

  const facilities = await response.json();
  console.log('received', facilities.data);
  return facilities.data;
}

export const FixUrlComponent = () => {
  const [facilities, setFacilities] = useState<Array<any>>([]);

  // console.log('facilities', facilities);
  React.useEffect(() => {
    async function fn() {
      console.log('fn running');
      // Retrieve the list of facilities
      const list = await getFacilities();
      console.log('***** call setFacilities with ', list.length, 'facilities');

      // Trim the list to what we need (DO THIS ON THE SERVER)
      const newlist = list.splice(0, 50);

      // Add index (for bgcolor)
      for (let i=0; i<newlist.length; i++) {
        newlist[i].index = i;
      }

      setFacilities(newlist);
    }
    if (facilities.length == 0) {
      fn();
    }
  }, []);

  // console.log('rendering', facilities.length);
  return (
    <div className="list-wrapper">
      <div>Showing {facilities.length} records</div>
      <div></div>
      {facilities.map((item, index) =>
        <FacilityComponent key={index} record={item} />
      )}
    </div>
  );
}

//         <li key={index}>{item.name} {item.city} {item.state}</li>

//        <FacilityComponent key={index} _facilityid={item.facilityid} _name={item.name} _url={item.url} />


//LoginComponent.propTypes = {
  //setCookie: PropTypes.func.isRequired
//}
