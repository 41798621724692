import React, { useState} from 'react';
import {withCookies, Cookies } from 'react-cookie';
import logo from './logo.svg';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import './App.css';

import {LoginComponent} from './components/loginComponent';
import { useCookies } from 'react-cookie';
import { FixUrlComponent } from './components/fixUrlComponent';

function App() {
  const [login, setLogin] = useState<boolean>(false);
  const [cookies, setCookie] = useCookies(['Authorization']);
  const [token, setToken] = useState();

  // Simple way to pass login state to app
  const enable = () => {
    console.log('enable', login);
    setLogin(true);
  }

  console.log('RENDERING', login);
  if (!login) {
    return <div><LoginComponent enable={enable}/></div>
  }

  return (
    <div className="App">
      <h1>HPricing</h1>
      <FixUrlComponent />
      {/*
      <BrowserRouter>
        <Switch>
          <Route path="/fixurl">        
          </Route>
        </Switch>
      </BrowserRouter>
      */}
    </div>
  );
}

export default App;
